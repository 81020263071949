<template>
    <validation-observer ref="action-form"  @submit.prevent>
      <form class="p-2" @submit.prevent>
        <b-row>
          <b-col cols="12">
            <!-- <validation-provider #default="{ errors }" :name="$t('Fecha')" rules="required" slim> -->
              <b-form-group :label="$t('Fecha')" label-for="date">
                <flat-pickr
                  :value="model.created_at"
                  :config="{ enableTime: true }"
                  name="date"
                  placeholder="dd/mm/YYYY"
                  class="form-control"
                  @input="model.created_at = $event, errorDate = false"
                />
                <small v-if="errorDate" class="text-danger">
                  {{ $t('DateRequired') }}
                </small>
              </b-form-group>
            <!-- </validation-provider> -->
          </b-col>
          <b-col cols="12">
            <validation-provider #default="{ errors }" :name="$t('Evento')" rules="required">
              <b-form-group :label="$t('Evento')" label-for="event">
                <BaseSelect
                  id="event"
                  :value="model.event"
                  :label="'name'"
                  :resource="'/types'"
                  :httpBody="{ type: 'event' }"
                  :filterBy="{ key:'show_task', value: 1 }"
                  @input="model.event = $event"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Responsable')"
              rules="required"
            >
              <b-form-group :label="$t('Responsable')" label-for="responsible">
                <b-form-input
                  id="responsible"
                  :placeholder="$t('Responsable')"
                  name="responsible" 
                  :value="model.responsible"
                  @input="model.responsible = $event"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group :label="$t('documentacion')" label-for="info_docs">
              <ImageDropzone 
                ref="documentation" 
                maxFiles="10"
                :files="files"
                @removed-file="removedFiles.push($event)"
                @image-selected="errorFile = false"
              />
              <small v-if="errorFile" class="text-danger">
                {{ $t('MinErrorFile') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import FormMixin from "@/mixins/formMixin";

export default {
  name: 'ExternalActionForm',
  components: {
    flatPickr,
    BaseSelect,
    ImageDropzone,
  },
  mixins: [FormMixin],
  data() {
    return {
      removedFiles: [],
      errorFile: false,
      errorDate: false,
    }
  },
  computed: {
    files() {
      return this.value?.documentation || []
    },
  },
  methods: {
    validateForm() {
      const documentationValid = this.$refs['documentation'].getFiles().length > 0
      const dateValid = !!this.model.created_at

      if(!documentationValid) {
        this.errorFile = true
      }
      if(!dateValid) {
        this.errorDate = true
      }

      return this.$refs['action-form'].validate() && documentationValid && dateValid
    },
    getDocumentationData() {
      return { documentation: this.$refs['documentation'].getFiles(), removedFiles: this.removedFiles }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
