<template>
  <div class="pb-1">
    <div>
      <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
        <div class="px-2">
          <b-row>
            <b-col sm="4">
              <b-form-group :label="$t('Usuario')" label-for="filter-order">
                <SelectUsers v-model="user" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </div>
    <b-table striped responsive :fields="fields" :items="items">
      <template #cell(created_at)="data">
        {{ toDateWithTime(data.item.date_ini).toString().split(" ")[0] }}
      </template>
      <template #cell(created_at_hour)="data">
        {{ toDateWithTime(data.item.date_ini).toString().split(" ")[1] }}
      </template>
      <template #cell(type)="data">
        <!-- <b-badge
            variant="ligth"
            pill
            style="border: 1px solid lightgray; color: black; font-weight: 500"
        > -->
          {{ $t(`typeevents.${data.item.event.alias}`) }}
        <!-- </b-badge> -->
      </template>
      <template #cell(action)="data">
        {{ data.item.name }}
      </template>
      <template #cell(users)="data">
          <div class="d-flex align-middle">
            <b-link
              v-if="data.item.users.length > 0"
              :to="{
                name: 'viewUser',
                params: { id: data.item.users[0].id },
              }"
            >
              {{ data.item.users[0].name }}
              {{ data.item.users[0].surname }}
            </b-link>
            <div v-show="data.item.users.length > 1" :id="`users-${data.item.id}`">
              <feather-icon class="text-primary ml-1" icon="PlusCircleIcon" size="19" />
            </div>
            <b-tooltip
              triggers="hover"
              :target="`users-${data.item.id}`"
              variant="primary"
              placement="right"
            >
              <ul style="padding-left: 5px;" class="mx-1 mb-0">
                <li v-for="user in data.item.users.slice(1)" :key=user.id class="text-left">
                  {{ user.name }} {{ user.surname }}
                </li>
              </ul>
            </b-tooltip>
          </div>
        </template>
      <template #cell(status)="data">
        <div class="mb-2 mt-1">
          <b-badge :variant="statusTask[data.item.status.alias]" class="mr-50">
            {{ $t(`status.tasks.${data.item.status.alias}`) }}
          </b-badge>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-link
          :to="{ name: 'viewTask', params: { id: data.item.id } }"
          class="mr-1"
        >
          <feather-icon icon="EyeIcon" size="16" />
        </b-link>
        <b-link
          v-if="!['closed', 'complete'].includes(data.item.status.alias)"
          :to="{ name: 'editTask', params: { id: data.item.id } }"
          class="mr-1"
        >
          <feather-icon icon="Edit2Icon" size="16" />
        </b-link>
      </template>
    </b-table>
    <div v-if="totalItems > 10" class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="pageLength"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { config } from "@/shared/app.config";
import {
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import SelectUsers from "@/components/selectUsers/SelectUsers";

export default {
  name: "NextActionsTable",
  props: {
    visibleFilter: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    }
  },
  components: {
    SelectUsers,
  },
  data() {
    return {
      currentPage: 1,
      pages: ["10", "15", "25"],
      pageLength: 10,
      user: null,
      statusTask: config.statusTasksVariants,
    };
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    pageLength() {
      this.loadData();
    },
    user() {
      this.currentPage = 1;
      this.loadData();
    },
  },
  computed: {
    ...mapGetters({
      items: "tasks/getItemsObject",
      totalItems: "tasks/getItemsObjectTotal",
      breakpoint: 'app/currentBreakPoint'
    }),
    fields () {
      const fields = [
        { key: "created_at", label: this.$t("Fecha") },
        { key: "created_at_hour", label: this.$t("Hora") },
        { key: "type", label: this.$t("Evento") },
        { key: "action", label: this.$t("Action") },
        { key: "users", label: this.$t("Usuarios") },
        { key: "status", label: this.$t("Estado") }
      ]

      return ['xs','sm'].includes(this.breakpoint) ? fields
       : [...fields, { key: "actions", label: this.$t("Acciones"), class: "text-right" }]
    }
  },
  methods: {
    ...mapActions({
      getListObject: "tasks/getListTasksObject",
    }),
    toDate(f) {
      if (f) {
        return TimestampToFlatPickr(f);
      }
      return "";
    },
    toDateWithTime(f) {
      if (f) {
        return TimestampToFlatPickrWithTime(f);
      }
      return "";
    },
    loadData() {
      this.getListObject({
        id: this.$route.params.id,
        page: this.currentPage,
        per_page: this.pageLength,
        user: this.user?.id,
        type: this.type
      });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
