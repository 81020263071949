var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-1"},[_c('div',[_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse"},model:{value:(_vm.visibleFilter),callback:function ($$v) {_vm.visibleFilter=$$v},expression:"visibleFilter"}},[_c('div',{staticClass:"px-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Usuario'),"label-for":"filter-order"}},[_c('SelectUsers',{model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1)],1)],1)])],1),_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.fieldsMantenimiento,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.toDateWithTime(data.item.created_at).toString().split(" ")[0])+" ")]}},{key:"cell(created_at_hour)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.toDateWithTime(data.item.created_at).toString().split(" ")[1])+" ")]}},{key:"cell(type)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(("typeevents." + (data.item.type))))+" ")]}},{key:"cell(assign_user)",fn:function(ref){
var value = ref.value;
return [_c('b-link',{staticClass:"link",attrs:{"to":{
          name: 'viewUser',
          params: { id: value.id },
        },"target":"_blank"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(value.name)+" "+_vm._s(value.surname)+" ")])])]}},{key:"cell(user)",fn:function(ref){
        var value = ref.value;
return [_c('b-link',{staticClass:"link",attrs:{"to":{
          name: 'viewUser',
          params: { id: value.id },
        },"target":"_blank"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(value.name)+" "+_vm._s(value.surname)+" ")])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"mb-2 mt-1"},[(data.item.status)?_c('b-badge',{staticClass:"mr-50",attrs:{"variant":_vm.returnStatus(data.item.status.alias, data.item.object_type)}},[_vm._v(" "+_vm._s(_vm.$t(("status." + (data.item.object_type) + "s." + (data.item.status.alias))))+" ")]):_vm._e(),(!data.item.apto)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("NO APTO"))+" ")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [(data.item.url)?_c('b-link',{attrs:{"href":data.item.url,"target":"_blank"}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"DownloadIcon"}})],1):_vm._e(),(data.item.certificate_id)?_c('b-link',{attrs:{"href":data.item.certificate_id,"target":"_blank","title":_vm.$t('Documento TSA')}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"PaperclipIcon"}})],1):_vm._e()]}}])}),(_vm.totalItems > 10)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.totalItems,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }